import {
  Alert,
  Button,
  FormControl,
  FormLabel,
  Stack,
  SvgIcon,
  TextField,
  Tooltip
} from '@mui/material'
import { Link } from '@phosphor-icons/react'
import {
  Body,
  Card,
  Footer,
  Head,
  Layout,
  Left,
  Right
} from './UserOnboardTellUs'

export function UserOnboardInvite(props) {
  const { slots = {} } = props

  const Slots = {
    Footer,
    ...slots
  }

  return (
    <Layout>
      <Left>
        <Head
          heading='Who do you collabrate with?'
          subheading='Get more done with your team.'
        />

        <Body
          component={Stack}
          spacing={3}
        >
          <TextField
            variant='outlined'
            placeholder='coworker@mapstack.io'
            InputLabelProps={{
              shrink: true
            }}
          />

          <TextField
            variant='outlined'
            placeholder='coworker@mapstack.io'
            InputLabelProps={{
              shrink: true
            }}
          />

          <TextField
            variant='outlined'
            placeholder='coworker@mapstack.io'
            InputLabelProps={{
              shrink: true
            }}
          />

          <FormControl>
            <FormLabel>Invite link for the workspace</FormLabel>
            <Stack
              direction='row'
              spacing={1}
              sx={{ mt: 1 }}
            >
              <TextField
                InputProps={{
                  startAdornment: (
                    <SvgIcon
                      component={Link}
                      weight='bold'
                      inheritViewBox
                      sx={{
                        width: 18,
                        height: 18,
                        mr: 1
                      }}
                    />
                  )
                }}
                variant='outlined'
                placeholder='https://mapstack.io/xxxx-xxxx-xxxx'
                InputLabelProps={{
                  shrink: true
                }}
                sx={{ flex: 1 }}
                disabled
              />
              <Button
                variant='outlined'
                color='primary'
              >
                Copy link
              </Button>
            </Stack>
          </FormControl>

          <Tooltip
            title='Credit will be awarded once collabrators sign up and validate their email address. Credit can be applied toward paid plans.'
            placement='right'
          >
            <Alert
              icon={false}
              severity='success'
              sx={{
                borderRadius: 1,
                width: 'fit-content',
                fontWeight: 600
              }}
            >
              Earn $20 in credit per invite!
            </Alert>
          </Tooltip>
        </Body>

        <Slots.Footer progress={100} />
      </Left>

      <Right>
        <Card teamType='Marketing' />
      </Right>
    </Layout>
  )
}

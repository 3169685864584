import {
  Box,
  Button,
  CircularProgress,
  circularProgressClasses,
  Divider,
  LinearProgress,
  Paper,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import { CaretLeft, CaretRight, SealCheck } from '@phosphor-icons/react'
import Joyride, { Step as JoyrideStep, TooltipRenderProps } from 'react-joyride'

import { LoadingButton } from '@mui/lab'
import { useState } from 'react'
import { Body, Head, Layout, Left, Map, Right } from './CobuilderMapPurpose'

function Tooltip(props: TooltipRenderProps) {
  const {
    backProps,
    closeProps,
    continuous,
    index,
    primaryProps,
    skipProps,
    step,
    tooltipProps
  } = props

  return (
    <Paper
      variant='elevation'
      elevation={8}
      sx={{ p: 2 }}
      {...tooltipProps}
    >
      <Typography
        variant='next:subtitle2'
        fontWeight={600}
      >
        {step.content}
      </Typography>
    </Paper>
  )
}

export function CobuilderMapProcess(props) {
  const { slots } = props

  const Slots = {
    StepForm: StepForm,
    ...slots
  }

  const [index, setIndex] = useState(0)

  const steps: JoyrideStep[] = [
    {
      target: '.MapTitle',
      content: 'Map name',
      disableBeacon: true
    }
  ]

  return (
    <>
      <Layout>
        <Left>
          <Head
            heading="Let's bring your map to life while we process your data"
            subheading='These quick details will make your map more useful and team-friendly.'
          />

          <Body>
            <Step>
              <Slots.StepForm />
            </Step>
          </Body>

          <Footer />
        </Left>

        <Right>
          <Map>
            <GeocodeProgress status='process' />

            <PropertiesViewer />
          </Map>

          <Joyride
            run={false}
            steps={steps}
            tooltipComponent={Tooltip}
            styles={{
              spotlight: {
                borderRadius: 8
              }
            }}
          />
        </Right>
      </Layout>
    </>
  )
}

function Footer() {
  return (
    <Stack
      direction='row'
      spacing={2}
      alignSelf='flex-end'
    >
      <LoadingButton
        loading
        variant='contained'
        loadingPosition='start'
        sx={{
          pl: 5
        }}
      >
        Processing data
      </LoadingButton>
    </Stack>
  )
}

export function GeocodeProgress(props) {
  const { status = 'analyze', success = 0, totals = 0, errors = 0 } = props

  let heading, subheading, variant

  if (status === 'analyze') {
    variant = 'indeterminate'
    heading = 'Analyzing your data...'
  }

  if (status === 'process') {
    variant = 'determinate'
    heading = 'Processing your data...'
    subheading = (
      <>
        Located <strong>{success}</strong> of <strong>{totals}</strong> records,
        with <strong>{errors}</strong> misses
      </>
    )
  }

  if (status === 'complete') {
    variant = 'determinate'
    heading = 'Data import complete'
    subheading = (
      <>
        Located <strong>{success}</strong> of <strong>{totals}</strong> records,
        with <strong>{errors}</strong> misses
      </>
    )
  }

  const value = Math.round((success / totals) * 100) || 0

  return (
    <Stack
      component={Paper}
      elevation={8}
      direction='row'
      alignItems='center'
      sx={{
        position: 'absolute',
        top: 16,
        right: 16,
        p: 2,
        borderRadius: 2,
        width: 400
      }}
      spacing={2}
    >
      {status === 'complete' && (
        <>
          <SvgIcon
            component={SealCheck}
            inheritViewBox
            sx={{
              width: 48,
              height: 48,
              color: (theme) => theme.palette.success.main
            }}
          />
        </>
      )}
      {status !== 'complete' && (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            variant={variant}
            size={54}
            thickness={4}
            {...(variant === 'determinate' && {
              value
            })}
            sx={(theme) => ({
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: 'round'
              }
            })}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography
              variant='next:caption'
              component='div'
              sx={{ color: 'text.secondary' }}
              fontWeight={700}
            >{`${value}%`}</Typography>
          </Box>
        </Box>
      )}
      <Stack direction='column'>
        <Typography
          variant='next:subtitle1'
          letterSpacing='tight'
          fontWeight={600}
        >
          {heading}
        </Typography>
        {subheading && (
          <Typography
            variant='next:subtitle2'
            letterSpacing='tight'
            fontWeight={500}
          >
            {subheading}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}

export function Step(props) {
  return (
    <Stack
      component={Paper}
      sx={{ p: 3, borderRadius: 2 }}
      spacing={3}
      elevation={8}
      {...props}
    />
  )
}

export function StepHeader(props) {
  return (
    <>
      <Typography
        variant='next:h5'
        letterSpacing='tight'
        fontWeight={700}
      >
        {props.heading}
      </Typography>

      {props.subheading && (
        <Typography
          variant='next:subtitle1'
          letterSpacing='tight'
        >
          {props.subheading}
        </Typography>
      )}
    </>
  )
}

export function StepController(props) {
  const { slotProps } = props
  return (
    <Stack
      direction='row'
      alignItems='center'
      spacing={2}
    >
      <Button
        variant='text'
        startIcon={
          <SvgIcon
            component={CaretLeft}
            inheritViewBox
          />
        }
        {...slotProps?.BackButton}
      >
        Back
      </Button>
      <Box flex={1}>
        <LinearProgress
          variant='determinate'
          {...slotProps?.LinearProgress}
        />
      </Box>
      <Button
        variant='text'
        endIcon={
          <SvgIcon
            component={CaretRight}
            inheritViewBox
          />
        }
        {...slotProps?.NextButton}
      >
        Next
      </Button>
    </Stack>
  )
}

const data = [
  {
    name: 'Espresso units',
    value: '12,345'
  },
  {
    name: 'Total sales',
    value: '$123,456'
  },
  {
    name: 'Start date',
    value: '2 January 2024'
  },
  {
    name: 'Stock',
    value: '25%'
  }
]

function PropertiesViewer() {
  return (
    <Stack
      component={Paper}
      sx={{
        position: 'absolute',
        bottom: 16,
        left: 16,
        width: 300,
        height: 400,
        borderRadius: 2
      }}
    >
      <Box p={2}>
        <Typography variant='next:subtitle1'>Clifton Branch</Typography>
      </Box>
      <Divider />
      <Stack
        p={2}
        spacing={1}
      >
        {data.map((item, index) => (
          <Stack
            key={`Data-${index}`}
            direction='column'
            spacing={1}
          >
            <Typography variant='next:subtitle2'>{item.name}</Typography>
            <Typography variant='next:body2'>{item.value}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}

export function StepForm(props) {
  const { heading, subheading, children } = props

  return (
    <>
      <StepHeader
        heading={heading}
        subheading={subheading}
      />

      {children}
    </>
  )
}

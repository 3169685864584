import {
  Box,
  Button,
  CardHeader,
  Chip,
  Divider,
  Paper,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import { Sparkle } from '@phosphor-icons/react'

import 'maplibre-gl/dist/maplibre-gl.css'
import MapGl from 'react-map-gl/maplibre'

import { BrandLogo } from '~ui-components/components/atoms/BrandLogo'
import { MapFileInput as FileInput } from '~ui-components/components/molecules/MapFileInput'

const purposes = [
  {
    label: 'Customer distribution',
    value: 'customer-distribution'
  },
  {
    label: 'Sales performance',
    value: 'sales-performance'
  },
  {
    label: 'Store locations',
    value: 'store-locations'
  },
  {
    label: 'Competitor analysis',
    value: 'competitor-analysis'
  },
  {
    label: 'Project planning',
    value: 'project-planning'
  },
  {
    label: 'Marketing campaign',
    value: 'marketing-campaign'
  },
  {
    label: 'Demograhic insights',
    value: 'demograhic-insights'
  },
  {
    label: 'Asset tracking & logistics',
    value: 'asset-tracking-logistics'
  },
  {
    label: 'Other',
    value: 'other'
  }
]

export function CobuilderMapPurpose(props) {
  const { slots } = props

  const Slots = {
    Footer,
    ...slots
  }

  return (
    <Layout>
      <Left>
        <Head heading='Tell us about the purpose of this map' />

        <Body>
          <MapPurposeInput />

          <DataUploadInput />
        </Body>

        <Slots.Footer />
      </Left>

      <Right>
        <Map />
      </Right>
    </Layout>
  )
}

export function Layout(props) {
  return (
    <Box
      key='CobuilderLayout'
      sx={{
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        backgroundColor: (theme) => theme.palette.primary.light,
        display: 'flex',
        flexDirection: 'row',
        p: 8,
        gap: 12,
        '> :first-child': {
          flex: 1,
          minWidth: '240px',
          maxWidth: '480px'
        },
        '> :nth-child(2)': {
          flex: 1,
          minWidth: '600px'
        }
      }}
      {...props}
    />
  )
}

export function Left(props) {
  return (
    <Stack
      key='CobuilderLeft'
      spacing={4}
      {...props}
    />
  )
}

export function Right(props) {
  return (
    <Stack
      key='CobuilderRight'
      {...props}
      sx={{ position: 'relative' }}
    />
  )
}

export function Head(props) {
  return (
    <Stack spacing={4}>
      <Stack
        direction='row'
        alignItems='center'
        spacing={1}
      >
        <SvgIcon
          component={Sparkle}
          weight='duotone'
          inheritViewBox
          sx={{
            color: (theme) => theme.palette.primary.main,
            width: 32,
            height: 32
          }}
        />
        <Typography
          variant='next:subtitle1'
          letterSpacing='tight'
          color='primary'
          fontWeight={600}
          pt={1}
        >
          Mapstack Cobuilder
        </Typography>
      </Stack>

      {props.heading && (
        <Typography
          variant='next:h3'
          fontWeight={700}
          letterSpacing='tight'
        >
          {props.heading}
        </Typography>
      )}

      {props.subheading && (
        <Typography
          variant='next:h6'
          letterSpacing='tight'
        >
          {props.subheading}
        </Typography>
      )}
    </Stack>
  )
}

export function Body(props) {
  return (
    <Stack
      {...props}
      spacing={4}
    />
  )
}

export function Footer(props) {
  const { slotProps } = props
  return (
    <Stack
      direction='row'
      spacing={2}
      alignSelf='flex-end'
    >
      <Button
        variant='text'
        {...slotProps?.SkipButton}
      >
        Skip
      </Button>
      <Button {...slotProps?.ContinueButton}>Continue</Button>
    </Stack>
  )
}

function MapPurposeInput() {
  return (
    <Stack
      direction='row'
      gap={2}
      flexWrap='wrap'
    >
      {purposes.map((purpose) => (
        <Chip
          key={purpose.value}
          label={purpose.label}
          sx={{
            px: 1,
            height: 42,
            borderRadius: 99,
            typography: 'next:subtitle1',
            letterSpacing: 'tight'
          }}
          onClick={() => {}}
        />
      ))}
    </Stack>
  )
}

function DataUploadInput() {
  return (
    <Stack spacing={4}>
      <Typography
        variant='next:subtitle1'
        letterSpacing='tight'
      >
        Import your data containing addresses, postcodes, or other location
        details to get started.
      </Typography>

      <FileInput />
    </Stack>
  )
}

export function Map(props) {
  const { children } = props
  return (
    <Paper
      key='CobuilderMap'
      className='Map'
      sx={{
        width: '100%',
        height: '100%'
      }}
      elevation={8}
    >
      <Stack
        direction='column'
        height='100%'
        borderRadius={2}
        overflow='hidden'
      >
        <CardHeader
          sx={{ p: 2 }}
          title={
            <Stack
              className='MapTitle'
              direction='row'
              alignItems='center'
              spacing={1}
              width='fit-content'
            >
              <BrandLogo />
              <span>Untitled map</span>
            </Stack>
          }
          titleTypographyProps={{
            variant: 'next:h6',
            fontWeight: 600,
            letterSpacing: 'tight'
          }}
        />
        <Divider />
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%'
          }}
        >
          <MapGl
            reuseMaps
            style={{
              width: '100%',
              height: '100%'
            }}
            mapStyle='https://api.maptiler.com/maps/267bea5c-db1b-4ae2-bf49-b3a20792a2c0/style.json?key=6dkE4jp3UXbczwUW17h4'
          />
          {children}
        </Box>
      </Stack>
    </Paper>
  )
}

import { SvgIcon } from '@mui/material'
import PropTypes from 'prop-types'

const sizes = {
  xs: {
    width: '90px',
    height: '18px'
  },
  sm: {
    width: '110px',
    height: '22px'
  },
  md: {
    width: '130px',
    height: '26px'
  },
  lg: {
    width: '160px',
    height: '32px'
  },
  xl: {
    width: '200px',
    height: '40px'
  }
}

const variants = {
  light: '#211338',
  dark: '#ffffff'
}

const MapstackLogoCopy = (props) => {
  const { variant = 'light', sx = {}, size, ...rest } = props

  const fillColor = variants[variant]

  return (
    <SvgIcon
      viewBox='0 0 400 80'
      fill='none'
      sx={[
        {
          display: 'block'
        },
        {
          ...sizes[size]
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      {...rest}
    >
      <path
        d='M140.185 53.7832V35.1597C140.185 34.2014 140.116 33.2769 139.977 32.387C139.838 31.4971 139.583 30.7329 139.212 30.0934C138.841 29.4546 138.344 28.9409 137.718 28.5529C137.093 28.1655 136.27 27.9706 135.252 27.9706C134.28 27.9706 133.434 28.176 132.716 28.5867C131.998 28.9974 131.396 29.5458 130.91 30.2303C130.424 30.9149 130.065 31.7024 129.833 32.5924C129.601 33.4823 129.485 34.4067 129.485 35.3655V53.7832H118.092V34.3383C118.092 32.3765 117.698 30.8244 116.911 29.6827C116.123 28.5417 114.873 27.9706 113.159 27.9706C111.353 27.9706 109.952 28.6789 108.957 30.0934C107.96 31.5086 107.462 33.243 107.462 35.297V53.7832H95.9998V25.435C95.9998 24.6429 96.4371 23.9137 97.1409 23.5312L105.795 18.832C106.357 18.5267 107.045 18.9273 107.045 19.5598V24.4107H107.184C107.602 23.6811 108.122 22.9724 108.747 22.2879C109.373 21.6033 110.125 20.9988 111.006 20.4735C111.885 19.9492 112.869 19.5266 113.958 19.2067C115.046 18.8879 116.239 18.7275 117.536 18.7275C120.037 18.7275 122.156 19.2301 123.893 20.2335C125.629 21.238 126.984 22.6762 127.957 24.5472C129.114 22.7667 130.596 21.3526 132.403 20.302C134.21 19.2528 136.409 18.7275 139.003 18.7275C141.365 18.7275 143.345 19.1498 144.943 19.9943C146.541 20.8392 147.826 21.9348 148.799 23.2808C149.771 24.6275 150.477 26.1335 150.918 27.7995C151.357 29.4662 151.578 31.0982 151.578 32.6954V53.7832H140.184H140.185Z'
        fill={fillColor}
      />
      <path
        d='M156.368 24.342C158.405 22.4246 160.779 20.9871 163.489 20.0284C166.198 19.07 168.965 18.5908 171.791 18.5908C174.709 18.5908 177.175 18.9446 179.19 19.6522C181.205 20.3602 182.838 21.4558 184.088 22.9387C185.338 24.4224 186.252 26.2937 186.832 28.553C187.411 30.812 187.7 33.4947 187.7 36.5978V53.7834H177.28V50.1546H177.071C176.191 51.5698 174.859 52.6651 173.077 53.4411C171.293 54.2165 169.36 54.6048 167.275 54.6048C165.886 54.6048 164.449 54.4222 162.968 54.0572C161.485 53.6926 160.13 53.0988 158.904 52.2767C157.676 51.4552 156.669 50.3599 155.882 48.9902C155.094 47.6211 154.701 45.9331 154.701 43.9238C154.701 41.4588 155.384 39.4733 156.75 37.9666C158.116 36.4606 159.877 35.2968 162.03 34.4747C164.184 33.6532 166.581 33.1056 169.221 32.8318C171.861 32.558 174.431 32.4211 176.932 32.4211V31.8727C176.932 30.1848 176.33 28.9404 175.126 28.1416C173.921 27.3436 172.439 26.9433 170.68 26.9433C169.058 26.9433 167.495 27.2856 165.99 27.9701C164.485 28.6547 163.199 29.4761 162.135 30.4352L156.368 24.3417L156.368 24.342ZM177.28 38.9256H175.82C174.57 38.9256 173.307 38.9836 172.034 39.0964C170.76 39.211 169.626 39.4282 168.63 39.7471C167.633 40.067 166.811 40.5347 166.164 41.1507C165.514 41.7668 165.19 42.5778 165.19 43.5812C165.19 44.2211 165.341 44.7687 165.642 45.2248C165.943 45.6816 166.325 46.0463 166.789 46.3201C167.251 46.5939 167.784 46.7888 168.387 46.9023C168.988 47.0169 169.568 47.0738 170.124 47.0738C172.439 47.0738 174.21 46.4465 175.438 45.1906C176.665 43.9361 177.279 42.2359 177.279 40.0901V38.9263L177.28 38.9256Z'
        fill={fillColor}
      />
      <path
        d='M230.398 36.598C230.398 38.9715 230.027 41.2539 229.286 43.4445C228.545 45.6357 227.469 47.5647 226.056 49.2303C224.643 50.8969 222.918 52.2321 220.88 53.2356C218.841 54.239 216.526 54.7416 213.933 54.7416C211.802 54.7416 209.787 54.3193 207.889 53.4748C205.989 52.631 204.507 51.4791 203.442 50.0175H203.303V61.7016C203.303 62.2611 202.993 62.7759 202.495 63.0441L193.541 67.8649C192.806 68.2606 191.91 67.7364 191.91 66.9111V19.6859H202.747V23.8627H202.956C204.021 22.4929 205.492 21.2949 207.367 20.2681C209.243 19.2406 211.454 18.7275 214.002 18.7275C216.503 18.7275 218.772 19.2067 220.811 20.1651C222.848 21.1241 224.573 22.4248 225.986 24.068C227.398 25.7109 228.487 27.6175 229.251 29.7847C230.016 31.9536 230.398 34.2241 230.398 36.5973L230.398 36.598ZM219.352 36.598C219.352 35.5027 219.178 34.4308 218.831 33.3799C218.483 32.3304 217.973 31.4059 217.303 30.6068C216.631 29.8088 215.785 29.1588 214.767 28.6555C213.747 28.154 212.567 27.9025 211.223 27.9025C209.926 27.9025 208.768 28.154 207.75 28.6555C206.73 29.1585 205.862 29.8199 205.145 30.6414C204.426 31.4629 203.87 32.3989 203.477 33.4487C203.083 34.4993 202.886 35.5715 202.886 36.6668C202.886 37.7621 203.083 38.835 203.477 39.8848C203.87 40.9354 204.426 41.8707 205.145 42.6922C205.862 43.5136 206.73 44.1758 207.75 44.6781C208.768 45.1806 209.926 45.4311 211.223 45.4311C212.566 45.4311 213.747 45.1806 214.767 44.6781C215.785 44.1762 216.631 43.5136 217.303 42.6922C217.973 41.8707 218.483 40.9239 218.831 39.851C219.178 38.7791 219.352 37.694 219.352 36.5987V36.598Z'
        fill={fillColor}
      />
      <path
        d='M252.959 27.9412C252.903 27.9101 252.846 27.8794 252.788 27.849C251.799 27.3241 250.728 26.9423 249.617 26.7761C248.014 26.5358 246.578 26.7073 245.302 27.2874C244.399 27.6981 243.948 28.4061 243.948 29.4095C243.948 30.3685 244.444 31.0531 245.441 31.4638C246.436 31.8745 248.046 32.3314 250.269 32.8336C251.565 33.1074 252.875 33.4958 254.195 33.9973C255.514 34.5002 256.707 35.161 257.772 35.9832C258.837 36.8046 259.694 37.8091 260.343 38.9959C260.991 40.1834 261.316 41.5979 261.316 43.2408C261.316 45.3866 260.875 47.2017 259.996 48.6843C259.115 50.1679 257.969 51.3662 256.557 52.2785C255.144 53.1922 253.558 53.8536 251.798 54.2644C250.037 54.6751 248.277 54.8805 246.518 54.8805C243.693 54.8805 240.926 54.4359 238.216 53.5453C235.682 52.713 233.543 51.5115 231.798 49.9399C231.622 49.781 231.665 49.4946 231.877 49.3856L240.193 45.1177C240.299 45.0632 240.424 45.0681 240.526 45.1292C240.865 45.3325 241.218 45.5249 241.586 45.7058C243.067 46.4365 244.643 46.8018 246.31 46.8018C247.236 46.8018 248.151 46.5852 249.054 46.1511C249.957 45.7177 250.409 44.9528 250.409 43.8575C250.409 42.808 249.853 42.032 248.741 41.5294C247.63 41.0275 245.892 40.5019 243.53 39.9542C242.326 39.6804 241.122 39.3158 239.918 38.859C238.713 38.4032 237.636 37.7871 236.688 37.01C235.738 36.2347 234.961 35.276 234.36 34.1342C233.758 32.9939 233.457 31.6248 233.457 30.0263C233.457 27.9719 233.896 26.2263 234.777 24.7881C235.656 23.3506 236.792 22.1757 238.181 21.2624C239.571 20.3497 241.11 19.6767 242.801 19.2423C244.491 18.8092 246.17 18.5923 247.838 18.5923C250.431 18.5923 252.967 18.9925 255.445 19.7906C257.752 20.5349 259.727 21.6242 261.373 23.0603C261.551 23.2161 261.514 23.5028 261.305 23.6157L253.287 27.9436C253.184 27.9992 253.061 27.9981 252.959 27.9419L252.959 27.9412Z'
        fill={fillColor}
      />
      <path
        d='M280.514 28.0392V41.0983C280.514 42.7021 280.827 43.9046 281.452 44.7068C282.078 45.509 283.2 45.9093 284.822 45.9093C285.377 45.9093 285.968 45.8646 286.593 45.7724C287.219 45.6816 287.74 45.5447 288.156 45.3617L288.295 53.5779C287.508 53.8518 286.512 54.0917 285.308 54.297C284.103 54.5024 282.899 54.6048 281.695 54.6048C279.379 54.6048 277.433 54.3184 275.859 53.7466C274.285 53.1756 273.022 52.352 272.073 51.2753C271.123 50.2003 270.441 48.9185 270.024 47.4317C269.607 45.9449 269.399 44.2852 269.399 42.4551V28.0392H263.841V22.3994C263.841 22.0351 264.026 21.6953 264.334 21.4938L269.329 18.2268V15.3503C269.329 14.5579 269.767 13.8283 270.471 13.4462L280.514 7.99951V19.686H288.643V28.0392H280.514V28.0392Z'
        fill={fillColor}
      />
      <path
        d='M292.751 24.342C294.789 22.4246 297.163 20.9871 299.872 20.0284C302.582 19.07 305.349 18.5908 308.175 18.5908C311.093 18.5908 313.559 18.9446 315.574 19.6522C317.588 20.3602 319.221 21.4558 320.472 22.9387C321.722 24.4224 322.636 26.2937 323.216 28.553C323.794 30.812 324.084 33.4947 324.084 36.5978V53.7834H313.663V50.1546H313.455C312.575 51.5698 311.243 52.6651 309.46 53.4411C307.676 54.2165 305.744 54.6048 303.659 54.6048C302.27 54.6048 300.833 54.4222 299.352 54.0572C297.869 53.6926 296.514 53.0988 295.288 52.2767C294.06 51.4552 293.053 50.3599 292.266 48.9902C291.478 47.6211 291.085 45.9331 291.085 43.9238C291.085 41.4588 291.768 39.4733 293.134 37.9666C294.5 36.4606 296.261 35.2968 298.414 34.4747C300.568 33.6532 302.965 33.1056 305.605 32.8318C308.245 32.558 310.815 32.4211 313.316 32.4211V31.8727C313.316 30.1848 312.714 28.9404 311.51 28.1416C310.305 27.3436 308.823 26.9433 307.063 26.9433C305.442 26.9433 303.878 27.2856 302.374 27.9701C300.869 28.6547 299.583 29.4761 298.518 30.4352L292.752 24.3417L292.751 24.342ZM313.663 38.9256H312.204C310.954 38.9256 309.691 38.9836 308.418 39.0964C307.143 39.211 306.009 39.4282 305.014 39.7471C304.017 40.067 303.195 40.5347 302.547 41.1507C301.898 41.7668 301.574 42.5778 301.574 43.5812C301.574 44.2211 301.724 44.7687 302.026 45.2248C302.327 45.6816 302.709 46.0463 303.173 46.3201C303.635 46.5939 304.168 46.7888 304.771 46.9023C305.372 47.0169 305.952 47.0738 306.507 47.0738C308.823 47.0738 310.594 46.4465 311.822 45.1906C313.049 43.9361 313.663 42.2359 313.663 40.0901V38.9263L313.663 38.9256Z'
        fill={fillColor}
      />
      <path
        d='M353.003 30.7093C352.353 29.8879 351.474 29.2267 350.362 28.7235C349.25 28.2219 348.116 27.9705 346.958 27.9705C345.753 27.9705 344.665 28.2104 343.693 28.6896C342.72 29.1688 341.886 29.8083 341.192 30.6063C340.496 31.4054 339.952 32.3299 339.559 33.3794C339.165 34.43 338.968 35.5476 338.968 36.7344C338.968 37.9212 339.153 39.0402 339.524 40.0893C339.894 41.1399 340.438 42.0644 341.156 42.8625C341.874 43.6616 342.73 44.2895 343.727 44.7453C344.723 45.2021 345.846 45.4298 347.097 45.4298C348.254 45.4298 349.4 45.214 350.535 44.7792C351.67 44.3461 352.585 43.7178 353.28 42.8963L359.602 50.4965C358.166 51.8656 356.313 52.9385 354.044 53.7146C351.774 54.4903 349.365 54.8783 346.819 54.8783C344.085 54.8783 341.535 54.4676 339.166 53.6461C336.797 52.8247 334.739 51.6264 332.992 50.0516C331.245 48.4771 329.871 46.5716 328.868 44.3349C327.866 42.0986 327.366 39.5655 327.366 36.7347C327.366 33.9508 327.867 31.4403 328.868 29.203C329.871 26.9674 331.245 25.0608 332.992 23.4864C334.739 21.9112 336.797 20.7024 339.166 19.8576C341.535 19.0134 344.062 18.5908 346.749 18.5908C348 18.5908 349.238 18.705 350.466 18.9331C351.692 19.1619 352.874 19.4703 354.009 19.8576C355.143 20.2456 356.186 20.7251 357.136 21.2951C358.084 21.8665 358.907 22.4934 359.602 23.178L353.002 30.7097L353.003 30.7093Z'
        fill={fillColor}
      />
      <path
        d='M399.999 53.7829H385.896L374.572 37.4188H374.363V53.7829H362.9V15.4956C362.9 14.7028 363.338 13.9729 364.043 13.5908L374.363 7.99951V33.5166H374.572L385.618 19.686H399.443L386.104 35.091L399.999 53.7829V53.7829Z'
        fill={fillColor}
      />
      <path
        d='M39.9824 79.9995C38.7091 79.9995 37.4365 79.6833 36.2758 79.0503L1.99574 60.3486C0.131151 59.3315 -0.54802 57.0092 0.478773 55.1621C1.50592 53.3143 3.84997 52.643 5.71349 53.6591L37.9621 71.2525C39.2265 71.942 40.7591 71.942 42.0232 71.2521L74.2521 53.6591C76.1163 52.6423 78.4604 53.3151 79.4868 55.1621C80.5132 57.0092 79.8344 59.3315 77.9698 60.3486L43.6905 79.0499C42.5301 79.683 41.2565 79.9995 39.9831 79.9995H39.9824Z'
        fill='#F0B514'
      />
      <path
        d='M39.9824 63.3184C38.7091 63.3184 37.4365 63.0022 36.2758 62.3691L1.99574 43.6675C0.131151 42.6503 -0.54802 40.3281 0.478773 38.481C1.50592 36.6332 3.84997 35.9619 5.71349 36.978L37.9621 54.5713C39.2265 55.2609 40.7591 55.2609 42.0232 54.571L74.2521 36.978C76.1163 35.9612 78.4604 36.6339 79.4868 38.481C80.5132 40.3281 79.8344 42.6503 77.9698 43.6675L43.6905 62.3687C42.5301 63.0018 41.2565 63.3184 39.9831 63.3184H39.9824Z'
        fill='#39A6F3'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M77.9167 19.6146L43.6695 0.930866C41.3944 -0.310289 38.6363 -0.310289 36.3616 0.930866L2.11441 19.6146C-0.660859 21.1288 -0.660859 25.0798 2.11441 26.5939L37.9854 46.1633C39.2494 46.8529 40.7814 46.8529 42.0454 46.1633L77.9163 26.5939C80.6916 25.0798 80.6916 21.1288 77.9163 19.6146H77.9167Z'
        fill='#17AB6A'
      />
    </SvgIcon>
  )
}

MapstackLogoCopy.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl'])
}

export { MapstackLogoCopy }

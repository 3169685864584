import { Button, Stack, SvgIcon } from '@mui/material'
import { Sparkle } from '@phosphor-icons/react'
import {
  GeocodeProgress,
  Step,
  StepController,
  StepHeader
} from './CobuilderMapProcess'
import { Body, Head, Layout, Left, Map, Right } from './CobuilderMapPurpose'

export function CobuilderMapComplete(props) {
  const { slots } = props

  const Slots = {
    StepController,
    ...slots
  }

  return (
    <Layout>
      <Left>
        <Head
          heading="You're all set!"
          subheading="Your data is ready, and your map is configured correctly. Let's get started!"
        />

        <Body>
          <Step>
            <StepHeader
              heading='Great job!'
              subheading="We've captured the details about your data. Now sit tight while we finish processing your file."
            />

            <Slots.StepController progress={100} />
          </Step>
        </Body>

        <Footer />
      </Left>
      <Right>
        <Map>
          <GeocodeProgress
            status='complete'
            success={100}
            totals={100}
            errors={0}
          />
        </Map>
      </Right>
    </Layout>
  )
}

function Footer() {
  return (
    <Stack
      direction='row'
      spacing={2}
      alignSelf='flex-end'
    >
      <Button
        variant='contained'
        startIcon={
          <SvgIcon
            component={Sparkle}
            inheritViewBox
          />
        }
      >
        Generate this map
      </Button>
    </Stack>
  )
}

import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  LinearProgress,
  menuClasses,
  MenuItem,
  Select as MuiSelect,
  TextField as MuiTextField,
  Paper,
  SelectProps,
  Skeleton,
  Stack,
  SvgIcon,
  TextFieldProps,
  Typography,
  TypographyProps
} from '@mui/material'
import { CaretDown } from '@phosphor-icons/react'
import React, { useId, useRef, useState } from 'react'
import { useMount, useUnmount, useWindowSize } from 'react-use'

import { BrandLogo } from '~ui-components/components/atoms/BrandLogo'

const options = [
  {
    label: 'Marketing',
    value: 'marketing'
  },
  {
    label: 'HR & Legal',
    value: 'hr-legal'
  },
  {
    label: 'Product & Design',
    value: 'product-design'
  },
  {
    label: 'Creative Production',
    value: 'creative-production'
  },
  {
    label: 'Engineering',
    value: 'engineering'
  },
  {
    label: 'Customer Service',
    value: 'customer-service'
  },
  {
    label: 'Operations & Planning',
    value: 'operations-planning'
  },
  {
    label: 'Finance',
    value: 'finance'
  },
  {
    label: 'IT & Support',
    value: 'it-support'
  },
  {
    label: 'Sales & Account Mgmt',
    value: 'sales-account-mgmt'
  },
  {
    label: 'Other / Personal',
    value: 'other-personal'
  }
]

const values: { [key: string]: string } = options.reduce(
  (acc, option) => ({
    ...acc,
    [option.value]: option.label
  }),
  {}
)

export function UserOnboardTellUs(props) {
  const { slots = {} } = props

  const Slots = {
    Footer,
    ...slots
  }

  return (
    <Layout>
      <Left>
        <Head
          heading='Tell us about yourself'
          subheading='To help us personalize your experience on Mapstack'
        />

        <Body>
          <TextStack>
            <Text>I work at </Text>
            <TextField placeholder='Organization' />
            <Text>.</Text>
          </TextStack>

          <TextStack>
            <Text>We're in the </Text>
            <TextField placeholder='industry type' />
            <Text>industry.</Text>
          </TextStack>

          <TextStack>
            <Text>I'm on the</Text>
            <Select
              defaultValue=''
              placeholder='team type'
            >
              {options.map((option) => {
                return (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{
                      borderRadius: 1
                    }}
                  >
                    {option.label}
                  </MenuItem>
                )
              })}
            </Select>
            <Text>team.</Text>
          </TextStack>
        </Body>

        <Slots.Footer progress={50} />
      </Left>

      <Right>
        <Card />
      </Right>
    </Layout>
  )
}

const Text = React.forwardRef<HTMLDivElement, TypographyProps>((props, ref) => {
  const { sx, ...rest } = props
  return (
    <Typography
      ref={ref}
      variant='next:h4'
      letterSpacing='tight'
      fontWeight={500}
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'nowrap'
        },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
      variantMapping={{
        'next:h4': 'div'
      }}
      {...rest}
    />
  )
})

const TextField = React.forwardRef<HTMLDivElement, TextFieldProps>(
  (props, ref) => {
    return (
      <MuiTextField
        ref={ref}
        {...props}
        variant='standard'
        sx={{
          fontWeight: 700
        }}
        inputProps={{
          sx: {
            fontWeight: 'inherit',
            fontSize: 'inherit',
            fontFamily: 'inherit',
            letterSpacing: 'inherit',
            fieldSizing: 'content',
            '::placeholder': {
              color: (theme) => theme.palette.grey[400]
            }
          }
        }}
        InputProps={{
          sx: {
            fontWeight: 'inherit',
            fontSize: 'inherit',
            fontFamily: 'inherit',
            letterSpacing: 'inherit',
            '&:after': {
              borderColor: (theme) => theme.palette.grey[400]
            },
            '&::before': {},
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderColor: (theme) => theme.palette.grey[400]
            },
            '&.Mui-focused:after': {}
          }
        }}
      />
    )
  }
)

const Select = React.forwardRef<HTMLDivElement, SelectProps<string>>(
  (props, ref) => {
    return (
      <FormControl
        ref={ref}
        variant='standard'
      >
        <MuiSelect
          {...props}
          displayEmpty
          IconComponent={(props) => (
            <SvgIcon
              component={CaretDown}
              inheritViewBox
              weight='bold'
              {...props}
            />
          )}
          renderValue={(value?: string) => {
            if (!value)
              return (
                <Text
                  fontWeight={700}
                  sx={{
                    color: (theme) => theme.palette.grey[400]
                  }}
                >
                  {props.placeholder}
                </Text>
              )
            return <Text fontWeight={700}>{values[value]}</Text>
          }}
          sx={{
            py: '4px',
            '&:after': {
              borderColor: (theme) => theme.palette.grey[400]
            },
            '&::before': {},
            '&:hover:not(.Mui-disabled, .Mui-error):before': {
              borderColor: (theme) => theme.palette.grey[400]
            },
            '&.Mui-focused:after': {}
          }}
          MenuProps={{
            sx: {
              [`& .${menuClasses.list}`]: {
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: 1,
                p: 1
              }
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            }
          }}
        />
      </FormControl>
    )
  }
)

export function Head(props) {
  const { heading, subheading } = props
  return (
    <Stack spacing={4}>
      <BrandLogo
        variant='logoCopy'
        size='lg'
      />
      <Stack spacing={1}>
        <Typography
          variant='next:h3'
          fontWeight={700}
        >
          {heading}
        </Typography>
        <Typography variant='next:h6'>{subheading}</Typography>
      </Stack>
    </Stack>
  )
}

export function Body(props) {
  return (
    <Stack
      flex={1}
      spacing={1}
      {...props}
    />
  )
}

export function Footer(props) {
  const { progress = 0, slotProps = {} } = props

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
    >
      <Box width={100}>
        <LinearProgress
          variant='determinate'
          value={progress}
          sx={{
            height: 8,
            borderRadius: 4
          }}
        />
      </Box>
      <Stack
        direction='row'
        spacing={2}
      >
        <Button
          variant='text'
          {...slotProps?.SkipButton}
        >
          Skip
        </Button>
        <Button {...slotProps?.ContinueButton}>Continue</Button>
      </Stack>
    </Stack>
  )
}

export function Card(props) {
  const { teamType } = props

  return (
    <Paper
      variant='elevation'
      elevation={8}
      sx={{
        my: 4,
        width: 360,
        borderRadius: 2
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        p={2}
        spacing={2}
      >
        <Avatar sx={{ width: 48, height: 48 }}>C</Avatar>
        <Typography variant='next:h6'>Chris Brown</Typography>
      </Stack>
      <Divider />
      <Stack
        p={2}
        spacing={2}
      >
        {!teamType && (
          <>
            <Skeleton
              variant='pill'
              width='80%'
              height={32}
              animation={false}
            />
            <Skeleton
              variant='pill'
              width='60%'
              height={32}
              animation={false}
            />
          </>
        )}
        {teamType && (
          <Chip
            label={teamType}
            color='secondary'
            sx={{
              px: 1,
              borderRadius: 3,
              height: 48,
              textAlign: 'left',
              justifyContent: 'flex-start',
              typography: 'next:body1',
              fontWeight: 600
            }}
          ></Chip>
        )}
      </Stack>
    </Paper>
  )
}

export function Layout(props) {
  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateAreas: 'Left Right',
        height: '100%',
        minHeight: '100vh',
        [theme.breakpoints.down(1024)]: {
          gridTemplateColumns: '1fr',
          gridTemplateAreas: `
            Left
            Right
          `,
          height: 'auto'
        }
      })}
      {...props}
    />
  )
}

export function Left(props) {
  return (
    <Paper
      className='Left'
      variant='elevation'
      component={Stack}
      sx={{
        p: 8,
        height: '100%',
        borderRadius: 0
      }}
      spacing={12}
      {...props}
    />
  )
}

export function Right(props) {
  return (
    <Stack
      className='Right'
      justifyContent='center'
      alignItems='center'
      {...props}
    />
  )
}

type D = { width: number; height: number }

function TextStack(props) {
  const { children, ...rest } = props

  const id = useId()

  let d: D[] = []
  const refs = useRef<Array<HTMLDivElement>>([])
  const [dimensions, setDimensions] = useState<D[]>([])

  const getHeights = () => {
    const current: D[] = []

    for (const element of refs.current) {
      const { width = 0, height = 0 } = element
        ? element.getBoundingClientRect()
        : { width: 0, height: 0 }

      current.push({
        width,
        height
      })
    }

    d = current
  }

  useWindowSize({
    onChange: () => {
      getHeights()
    }
  })

  useMount(() => {
    setDimensions(d)
  })

  useUnmount(() => {
    refs.current = []
    d = []
  })

  const maxWidth = Math.max(...dimensions.map((d) => d.width))
  const maxHeight = Math.max(...dimensions.map((d) => d.height))

  return (
    <Stack
      component={Text}
      direction='row'
      gap={1}
      flexWrap='wrap'
      sx={{
        '--item-max-width': `${maxWidth}px`,
        '--item-max-height': `${maxHeight}px`,
        '> *': {
          height: 'var(--item-max-height)'
        }
      }}
      {...rest}
    >
      {children.map((child, index) => {
        return React.cloneElement(child, {
          key: `${id}:${index}`,
          ref: (element: HTMLDivElement) => {
            refs.current = [...(refs.current || []), element]

            const { width = 0, height = 0 } = element
              ? element.getBoundingClientRect()
              : { width: 0, height: 0 }

            d = [...d, { width, height }]
          }
        })
      })}
    </Stack>
  )
}
